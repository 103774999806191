import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as C } from 'assets/icons/c.svg';
import { ReactComponent as Mover } from 'assets/icons/mover.svg';
import { ReactComponent as Sui } from 'assets/icons/sui.svg';
import { ReactComponent as ZkSynk } from 'assets/icons/zksynk.svg';
import { ReactComponent as Celestia } from 'assets/icons/celestia.svg';
import { ReactComponent as Interchain } from 'assets/icons/interchain.svg';
import { ReactComponent as Crosschain } from 'assets/icons/crosschain.svg';
import { ReactComponent as Ethereum } from 'assets/icons/ethereum.svg';
import { ReactComponent as ZkPolygon } from 'assets/icons/zkpolygon.svg';

import { ReactComponent as Token } from 'assets/images/token.svg';
import { ReactComponent as Shield } from 'assets/images/shield.svg';
import { ReactComponent as Lightning } from 'assets/images/lightning.svg';

import { ReactComponent as Aptos } from 'assets/chains/aptos.svg';
import { ReactComponent as Arbitrum } from 'assets/chains/arbitrum.svg';
import { ReactComponent as BnB } from 'assets/chains/bnb.svg';
import { ReactComponent as Optimism } from 'assets/chains/optimism.svg';
import { ReactComponent as Polygon } from 'assets/chains/polygon.svg';
import { ReactComponent as SuiChain } from 'assets/chains/sui.svg';

import { ReactComponent as AptosPartners } from 'assets/partners/aptos.svg';
import { ReactComponent as BinancePartners } from 'assets/partners/binance.svg';
import { ReactComponent as BitgetPartners } from 'assets/partners/bitget.svg';
import { ReactComponent as BrilliancePartners } from 'assets/partners/brilliance.svg';
import { ReactComponent as GainsPartners } from 'assets/partners/gains.svg';
import { ReactComponent as MaterusPartners } from 'assets/partners/materus.svg';
import { ReactComponent as NextPartners } from 'assets/partners/next.svg';
import { ReactComponent as PolkaPartners } from 'assets/partners/polka.svg';
import { ReactComponent as ZephysPartners } from 'assets/partners/zephys.svg';

import { ReactComponent as Discord } from 'assets/social/discord.svg';
import { ReactComponent as Gitbook } from 'assets/social/gitbook.svg';
import { ReactComponent as Github } from 'assets/social/github.svg';
import { ReactComponent as Medium } from 'assets/social/medium.svg';
import { ReactComponent as Twitter } from 'assets/social/twitter.svg';
import { ReactComponent as YouTube } from 'assets/social/youtube.svg';

export const Icons = {
    Logo,
    Token,
    Shield,
    Lightning,
    Aptos,
    Arbitrum,
    BnB,
    Optimism,
    Polygon,
    AptosPartners,
    BinancePartners,
    BitgetPartners,
    BrilliancePartners,
    GainsPartners,
    MaterusPartners,
    NextPartners,
    PolkaPartners,
    ZephysPartners,
    ArrowRight,
    Discord,
    Gitbook,
    Github,
    Medium,
    Twitter,
    YouTube,
    C,
    Mover,
    Sui,
    ZkSynk,
    Celestia,
    Interchain,
    Crosschain,
    Ethereum,
    SuiChain,
    ZkPolygon,
};
