import { FC } from 'react';
import { cn } from '@bem-react/classname';

import './Link.scss';

const CnLink = cn('link');

interface ILinkProps {
    label?: string;
    href?: string;
    view?: 'header' | 'banner';
    size?: 'm' | 'l';
}

export const Link: FC<ILinkProps> = ({
    label,
    href,
    view = 'header',
    size = 'm',
}) => {
    return (
        <a
            href={href}
            target="_blank"
            className={CnLink({ view, size })}
            rel="noreferrer"
        >
            {label}
        </a>
    );
};
