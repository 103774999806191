import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { Block } from 'components';
import { FC, useMemo } from 'react';

import './Partners.scss';

const CnPartners = cn('partners');

const partnersItemsFirstRow = [
    <Icons.BitgetPartners />,
    <Icons.BinancePartners />,
    <Icons.AptosPartners />,
    <Icons.GainsPartners />,
    <Icons.MaterusPartners />,
];

const partnersItemsSecondRow = [
    <Icons.ZephysPartners />,
    <Icons.PolkaPartners />,
    <Icons.NextPartners />,
    <Icons.BrilliancePartners />,
];

export const Partners: FC = () => {
    const partnersFirstRowContnet = useMemo(() => {
        return partnersItemsFirstRow.map((icon, index) => (
            <div key={index} className={CnPartners('item', { first: true })}>
                {icon}
            </div>
        ));
    }, []);

    const partnersSecondRowContnet = useMemo(() => {
        return partnersItemsSecondRow.map((icon, index) => (
            <div key={index} className={CnPartners('item', { second: true })}>
                {icon}
            </div>
        ));
    }, []);

    return (
        <Block label="people who believed in us" title="Partners & Investors">
            <div className={CnPartners()}>
                <div className={CnPartners('row')}>
                    {partnersFirstRowContnet}
                    {partnersSecondRowContnet}
                </div>
            </div>
        </Block>
    );
};
