import { cn } from '@bem-react/classname';
import { FC, useMemo } from 'react';
import { Block } from 'components';

import './Networks.scss';
import { Icons } from 'assets';

interface INetworksItemProps {
    icon: any;
    title: string;
}

const networksItems: INetworksItemProps[] = [
    {
        title: 'Optimism',
        icon: <Icons.Optimism />,
    },
    {
        title: 'Arbitrum',
        icon: <Icons.Arbitrum />,
    },
    {
        title: 'Aptos',
        icon: <Icons.Aptos />,
    },
    {
        title: 'BNB',
        icon: <Icons.BnB />,
    },
    {
        title: 'Polygon',
        icon: <Icons.Polygon />,
    },
    {
        title: 'Sui',
        icon: <Icons.SuiChain />,
    },
];

const CnNetworks = cn('networks');

export const Networks: FC = () => {
    const networksContent = useMemo(() => {
        return networksItems.map((props, index) => (
            <NetworksItem {...props} key={index} />
        ));
    }, []);
    return (
        <Block label="SUPPORTED CHAINS" title="Networks">
            <div className={CnNetworks()}>{networksContent}</div>
        </Block>
    );
};

const CnNetworksItem = cn('networksItem');

const NetworksItem: FC<INetworksItemProps> = ({ icon, title }) => {
    return (
        <div className={CnNetworksItem()}>
            <div className={CnNetworksItem('icon')}>{icon}</div>
            <div className={CnNetworksItem('title')}>{title}</div>
        </div>
    );
};
