import { cn } from '@bem-react/classname';
import { FC, useMemo } from 'react';

import './Block.scss';

const CnBlock = cn('block');

interface IBlockProps {
    children?: React.ReactNode;
    label?: string;
    title?: any;
    description?: string;
    container?: boolean;
    style?: any;
}

export const Block: FC<IBlockProps> = ({
    children,
    label,
    title,
    description,
    container = true,
    style,
}) => {
    const labelContent = useMemo(() => {
        if (!label) return null;

        return <div className={CnBlock('label')}>{label}</div>;
    }, [label]);

    const titleContent = useMemo(() => {
        if (!title) return null;

        return <div className={CnBlock('title')}>{title}</div>;
    }, [title]);

    const descriptionContent = useMemo(() => {
        if (!description) return null;

        return <div className={CnBlock('description')}>{description}</div>;
    }, [description]);

    return (
        <div style={style} className={CnBlock({ container })}>
            {labelContent}
            {titleContent}
            {descriptionContent}
            <div className={CnBlock('content')}>{children}</div>
        </div>
    );
};
