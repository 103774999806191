import { FC } from 'react';
import { cn } from '@bem-react/classname';

import './Parallax.scss';

const CnParallax = cn('parallax');

interface IParallaxProps {}

export const Parallax: FC<IParallaxProps> = () => {
    return (
        <div className={CnParallax()}>
            <div id="stars"></div>
            <div id="stars2"></div>
            <div id="stars3"></div>
        </div>
    );
};
