import { cn } from '@bem-react/classname';

import './Button.scss';

const CnButton = cn('button');

interface IButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    size?: 'm' | 's' | 'l';
    view?: 'default' | 'gradient' | 'black';
    bordered?: boolean;
}

export const Button: React.FC<IButtonProps> = ({
    children,
    size = 'm',
    view = 'default',
    bordered = false,
    ...props
}) => {
    return (
        <button {...props} className={CnButton({ size, view, bordered })}>
            {children}
        </button>
    );
};
