import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { Button } from 'components';
import { FC } from 'react';

import './Info.scss';

const CnInfo = cn('info');

export const Info: FC = () => {
    return (
        <div className={CnInfo()}>
            <InfoItem
                view="white"
                title="Token"
                description="$MOVER is the Mover bridge utility token. With a total supply of 100,000,000, $MOVER can be used to reduce bridging fees, stake to earn, boost LP rewards, use dual staking, and more."
                button={
                    <Button
                        onClick={() =>
                            window.open(
                                'https://www.bitget.com/en/spot/MOVERUSDT_SPBL?type=spot',
                                '_blank',
                            )
                        }
                        view="black"
                    >
                        Buy Mover <Icons.ArrowRight />{' '}
                    </Button>
                }
            />
            <InfoItem
                view="black"
                title="Documentation"
                description="The Mover Gitbook is now live, giving you access to all the vital information about Mover in one place. We regularly update the platform, so be sure to stay tuned for more!"
                button={
                    <Button
                        onClick={() =>
                            window.open(
                                'https://docs.mov3r.xyz/mover-gitbook/',
                                '_blank',
                            )
                        }
                        view="black"
                        bordered
                    >
                        Read docs <Icons.ArrowRight />{' '}
                    </Button>
                }
            />
        </div>
    );
};

interface IInfoItemProps {
    view: 'white' | 'black';
    title: string;
    description: string;
    button: any;
}

const CnInfoItem = cn('infoItem');

const InfoItem: FC<IInfoItemProps> = ({ view, title, description, button }) => {
    return (
        <div className={CnInfoItem({ view })}>
            <div className={CnInfoItem('title')}>{title}</div>
            <div className={CnInfoItem('description')}>{description}</div>
            <div className={CnInfoItem('button')}>{button}</div>
        </div>
    );
};
