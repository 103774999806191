import { FC } from 'react';
import { cn } from '@bem-react/classname';
import { Button, Link, Parallax } from 'components';

import './Banner.scss';

const bannerImage = require('assets/images/banner.png');

const CnBanner = cn('banner');

export const Banner: FC = () => {
    return (
        <div className={CnBanner()}>
            <div className={CnBanner('container')}>
                <Parallax />
                <div className={CnBanner('gradient')}></div>
                <div className={CnBanner('content')}>
                    <div className={CnBanner('title')}>Blazing fast bridge</div>
                    <div className={CnBanner('actions')}>
                        <Button
                            onClick={() =>
                                window.open('https://app.mov3r.xyz/', '_blank')
                            }
                            view="default"
                            size="l"
                        >
                            Use Mover
                        </Button>

                        <Link
                            href="https://docs.mov3r.xyz/mover-gitbook/"
                            view="banner"
                            size="l"
                            label="Read docs"
                        />
                    </div>
                </div>
                <div className={CnBanner('media')}>
                    <img src={bannerImage} alt="banner" />
                </div>
            </div>
        </div>
    );
};
