import { cn } from '@bem-react/classname';
import { FC, useMemo } from 'react';

import './About.scss';
import { Block } from 'components';
import { Icons } from 'assets';

const CnAbout = cn('about');

interface IAboutItemProps {
    title: string;
    description: any;
    image: any;
    orient: 'column' | 'row';
}

const aboutItems: IAboutItemProps[] = [
    {
        title: 'Token utility',
        description:
            'The long-term success of any token depends on its utility. That’s why $MOVER has 6 distinct utility mechanics, which were carefully crafted to create lasting demand and incentivize holding.',
        image: <Icons.Token />,
        orient: 'column',
    },
    {
        title: 'Security',
        description: (
            <>
                Mover prioritizes security above all else. This focus ensures
                the utmost integrity and safety of the assets being transferred.
                That’s why{' '}
                <a
                    href="https://bit.ly/tetragaze-mover-audit"
                    target="_blank"
                    rel="noreferrer"
                >
                    we were audited
                </a>{' '}
                by one of the leading security companies in the cross-chain
                space.
            </>
        ),
        image: <Icons.Shield />,
        orient: 'column',
    },
    {
        title: 'Bridging time',
        description: (
            <>
                Fast bridging enables users to take advantage of arbitrage
                opportunities and make timely transactions without delays. To
                demonstrate Mover's speed, we performed{' '}
                <a
                    href="https://bit.ly/3KhNYzm"
                    target="_blank"
                    rel="noreferrer"
                >
                    a series of specialized tests
                </a>{' '}
                that shows how much faster is Mover compared to other bridges.
            </>
        ),
        image: <Icons.Lightning />,
        orient: window.screen.width > 750 ? 'row' : 'column',
    },
];

export const About: FC = () => {
    const itemsContent = useMemo(() => {
        return aboutItems.map(
            (props, index) => <AboutItem key={index} {...props} />,
            [],
        );
    }, []);

    return (
        <Block
            label="features"
            title="Why Mover?"
            description="Mover is a blazing fast liquidity bridge connecting EVM and non-EVM chains."
        >
            <div className={CnAbout()}>{itemsContent}</div>
        </Block>
    );
};

const CnAboutItem = cn('aboutItem');

const AboutItem: FC<IAboutItemProps> = ({
    title,
    description,
    image,
    orient,
}) => {
    return (
        <div className={CnAboutItem({ orient })}>
            <div className={CnAboutItem('head')}>
                <div className={CnAboutItem('title')}>{title}</div>
                <div className={CnAboutItem('description')}>{description}</div>
            </div>
            <div className={CnAboutItem('image')}>
                <div className={CnAboutItem('image-shadow')}></div>
                {image}
            </div>
        </div>
    );
};
