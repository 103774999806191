import { cn } from '@bem-react/classname';
import { FC } from 'react';
import { Icons } from 'assets';

import './Footer.scss';

const CnFooter = cn('footer');

export const Footer: FC = () => {
    return (
        <div className={CnFooter()}>
            <div className={CnFooter('left')}>
                <div>
                    <Icons.C />
                    2023 Mover
                </div>
                <div
                    onClick={() =>
                        window.open('mailto:moverxyz@gmail.com', '_blank')
                    }
                >
                    Contact us
                </div>
                <div
                    onClick={() =>
                        window.open(
                            'https://coinmarketcap.com/currencies/mover-xyz/',
                            '_blank',
                        )
                    }
                >
                    MOVER token
                </div>
            </div>
            <div className={CnFooter('right')}>
                <div
                    onClick={() =>
                        window.open('https://mov3r.xyz/privacy.html', '_blank')
                    }
                >
                    Privacy Policy
                </div>
                {/* <div>Terms of Service</div> */}
            </div>
        </div>
    );
};
