import { cn } from '@bem-react/classname';
import { Block } from 'components';
import { FC, useMemo } from 'react';

import './Community.scss';
import { Icons } from 'assets';

const CnCommunity = cn('community');

interface ICommunityItemProps {
    icon: any;
    title: string;
    description: string;
    href: string;
}

const communityItems: ICommunityItemProps[] = [
    {
        title: 'Twitter',
        icon: <Icons.Twitter />,
        description: 'Follow us to get the latest news and updates',
        href: 'https://twitter.com/moverxyz',
    },
    {
        title: 'Discord',
        icon: <Icons.Discord />,
        description: 'Enjoy conversations with other community members',
        href: 'https://discord.com/invite/45us2C3NBw',
    },
    // {
    //     title: 'YouTube',
    //     icon: <Icons.YouTube />,
    //     description: 'Watch our videos',
    //     href: '',
    // },
    {
        title: 'GitHub',
        icon: <Icons.Github />,
        description: 'Analyze our code',
        href: 'https://github.com/mov3r/',
    },
    {
        title: 'GitBook',
        icon: <Icons.Gitbook />,
        description: 'Read our docs',
        href: 'https://docs.mov3r.xyz/mover-gitbook/',
    },
    {
        title: 'Medium',
        icon: <Icons.Medium />,
        description: 'Read our articles',
        href: 'https://moverxyz.medium.com/',
    },
];

export const Community: FC = () => {
    const communityItemsContent = useMemo(() => {
        return communityItems.map((props, index) => (
            <CommunityItem key={index} {...props} />
        ));
    }, []);

    return (
        <Block
            label="welcome to mover"
            title={
                <>
                    Join our
                    <br /> global community
                </>
            }
            style={{ marginBottom: 96 }}
        >
            <div className={CnCommunity()}>{communityItemsContent}</div>
        </Block>
    );
};

const CnCommunityItem = cn('communityItem');

const CommunityItem: FC<ICommunityItemProps> = ({
    icon,
    title,
    description,
    href,
}) => {
    return (
        <div
            onClick={() => window.open(href, '_blank')}
            className={CnCommunityItem()}
        >
            <div className={CnCommunityItem('icon')}>{icon}</div>
            <div className={CnCommunityItem('contnet')}>
                <div className={CnCommunityItem('title')}>{title}</div>
                <div className={CnCommunityItem('description')}>
                    {description}
                </div>
            </div>
        </div>
    );
};
