import { FC } from 'react';
import {
    About,
    Banner,
    Community,
    Footer,
    Header,
    Info,
    Networks,
    Parallax,
    Partners,
    Roadmap,
} from 'components';
import { cn } from '@bem-react/classname';

import './App.scss';

const CnApp = cn('app');

export const App: FC = () => {
    return (
        <div className={CnApp()}>
            <Header />

            <div className={CnApp('content')}>
                <Banner />

                <About />

                <Networks />

                <Roadmap />

                <Partners />

                <Info />

                <Community />

                <Footer />
            </div>
        </div>
    );
};
