import { FC, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Button, Link, Logo } from 'components';

import './Header.scss';

const links = [
    {
        label: 'Discord',
        href: 'https://discord.com/invite/45us2C3NBw',
    },
    {
        label: 'Twitter',
        href: 'https://twitter.com/moverxyz',
    },
    {
        label: 'Medium',
        href: 'https://moverxyz.medium.com/',
    },
    {
        label: 'GitHub',
        href: 'https://github.com/mov3r/',
    },
    {
        label: 'GitBook',
        href: 'https://docs.mov3r.xyz/mover-gitbook/',
    },
];

const CnHeader = cn('header');

export const Header: FC = () => {
    const linksContent = useMemo(() => {
        return links.map((props, index) => <Link key={index} {...props} />);
    }, []);

    return (
        <div className={CnHeader()}>
            <Logo />

            {/* <div className={CnHeader('links')}>{linksContent}</div> */}

            <div className={CnHeader('action')}>
                <Button
                    onClick={() =>
                        window.open('https://app.mov3r.xyz/', '_blank')
                    }
                >
                    Use Mover
                </Button>
            </div>
        </div>
    );
};
